import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useModal } from '@contexts/modal';
import ModalFooter from '@components/elements/modal/modal-footer';

const ModalDialogAction = dynamic(() => import('@components/core/dialog/dialog-action'));
const ModalDialogContent = dynamic(() => import('@components/core/dialog/dialog-content'));
const Modal = dynamic(() => import('@components/elements/modal'));
const ModalHeader = dynamic(() => import('@components/elements/modal/modal-header'));
const ModalBody = dynamic(() => import('@components/elements/modal/modal-body'));

const ModalDialog = () => {
  const [open, setOpen] = useState(false);

  const modState = useModal();

  useEffect(() => {
    if (modState?.modal) setOpen(true);
    if (!modState?.modal) setOpen(false);
  }, [modState?.modal]);

  useEffect(() => {
    if (!open) modState?.closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Modal
        size={modState?.modal?.size ? modState?.modal.size : 'base'}
        active={open}
        toggler={() => setOpen(false)}>
        {modState?.modal?.header && (
          <ModalHeader toggler={() => setOpen(false)}>{modState?.modal.header}</ModalHeader>
        )}
        <ModalBody
          className={`${
            modState?.modal?.className
              ? modState?.modal.className
              : 'h-full flex-grow-1 overflow-auto'
          }`}>
          <ModalDialogContent modal={modState?.modal} />
          <ModalDialogAction modal={modState?.modal} setOpen={(e: any) => setOpen(e)} />
        </ModalBody>
        {modState?.modal?.footer && <ModalFooter>{modState?.modal.footer}</ModalFooter>}
      </Modal>
    </>
  );
};

export default ModalDialog;
